import './modules/search';

// ====================================
// Search page load more functionality
// (Based on /resources/js/blog.ts)
// ====================================

const articlesList = document.querySelector<HTMLElement>('#article-results');
const pagesList = document.querySelector<HTMLElement>('#page-results');
const articlesMoreBtn = document.querySelector<HTMLElement>('#article-results__view-more-btn');
const pagesMoreBtn = document.querySelector<HTMLElement>('#page-results__view-more-btn');

const sortByEls = document.querySelectorAll<HTMLElement>('.js-blog-sortBy');

const queryStrings = new URLSearchParams(window.location.search);
const query = queryStrings.get('q') || '';

const postsPerPage = 6;
const states = {
  IDLE: 0,
  WORKING: 1,
  DONE: 2,
};

let state = states.IDLE;
let currentPage = 1;

function init() {
  if (articlesList && articlesMoreBtn) {
    articlesMoreBtn.addEventListener('click', (e) => {
      e.preventDefault();

      getNextPage({
        type: 'articles',
        query,
        container: articlesList,
      });
    });
  }

  if (pagesList && pagesMoreBtn) {
    pagesMoreBtn.addEventListener('click', (e) => {
      e.preventDefault();

      getNextPage({
        type: 'pages',
        query,
        container: pagesList,
      });
    });
  }

  const articlesListTotal = articlesList?.dataset['total']
    ? parseInt(articlesList?.dataset['total'])
    : 0;

  if (postsPerPage >= articlesListTotal) {
    articlesMoreBtn?.remove();
  }

  const pagesListTotal = pagesList?.dataset['total']
    ? parseInt(pagesList?.dataset['total'])
    : 0;

  if (postsPerPage >= pagesListTotal) {
    pagesMoreBtn?.remove();
  }

  sortByEls.forEach((item) => {
    const toggle = item.querySelector('.js-blog-sortBy-toggle');
    const list = item.querySelector('.js-blog-sortBy-list');

    const options = item.querySelectorAll('.js-sortBy-btn');

    toggle?.addEventListener('click', (event) => {
      event.preventDefault();
      if (toggle.classList.contains('sortBy__toggle--open')) {
        toggle.classList.remove('sortBy__toggle--open');
        list?.setAttribute('aria-hidden', 'true');
      } else {
        toggle.classList.add('sortBy__toggle--open');
        list?.removeAttribute('aria-hidden');
      }
    });

    options.forEach((option) => {
      option.addEventListener('click', (e) => {
        e.preventDefault();
        let oldBtn = item.querySelector('.sortBy__button--selected');

        oldBtn?.classList.remove('sortBy__button--selected');

        option.classList.add('sortBy__button--selected');

        // let toggles = document.querySelectorAll('.sortBy__toggle');

        if (toggle) {
          toggle.innerHTML = option.innerHTML;
        }

        // function to make call for sorted posts goes here

        // close dropdown
        toggle?.classList.remove('sortBy__toggle--open');
        list?.setAttribute('aria-hidden', 'true');
      });
    });
  });
}

/**
 * Fetch the next page of results.
 * @param params
 * @param params.type The type of results to fetch.
 * @param params.query The query to send.
 * @param params.container The container to append the results to.
 */
async function getNextPage(
  { type, query, container }
  : { type: 'articles' | 'pages', query: string, container: HTMLElement },
) {
  if (state === states.WORKING) {
    return;
  }

  const route = type === 'articles'
    ? '/blog/paginate'
    : '/search/paginate';

  const { html, items } = await fetch(
    `${route}?q=${encodeURIComponent(query)}&page=${currentPage + 1}`,
  ).then((r) => {
    currentPage++;

    return r.json();
  });

  const itemsToAppend = html.map((postStr) => {
    const classesToAdd = type === 'articles'
      ? [
        'search-results__grid-item',
        // TODO: It doesn't look like this actually does anything in the CSS?
        // 'search-results__grid-item--hidden',
      ]
      : ['page-card'];
    let div = document.createElement('div');
    div.classList.add(...classesToAdd);

    div.innerHTML = postStr;

    return div;
  });

  container.append(...itemsToAppend);

  // const newPosts = container.querySelectorAll(
  //   '.search-results__grid-item--hidden',
  // );

  // newPosts.forEach((post) => {
  //   post.classList.remove('search-results__grid-item--hidden');
  // });

  const total = container?.dataset['total']
    ? parseInt(container?.dataset['total'])
    : 0;

  if (items.skip + postsPerPage >= total) {
    switch (type) {
      case 'articles':
        articlesMoreBtn?.remove();
        break;
      case 'pages':
        pagesMoreBtn?.remove();
        break;
    }

    state = states.DONE;
  }
}

init();
