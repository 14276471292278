// Used on search page, 404 page, and search "view all" product search pages

import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { history } from 'instantsearch.js/es/lib/routers';
import { stats, configure } from 'instantsearch.js/es/widgets';
import { connectInfiniteHits, connectSearchBox } from 'instantsearch.js/es/connectors';
import $ from 'jquery';
// import './collection/view-toggle';

import {
  formatAsMoney,
  extractID,
  getSizedShopifyImageUrl,
} from '../modules/utils';

const DEFAULT_INDEX = 'shopify_products';
const searchClient = algoliasearch(
  'NOZ1QDJQX7',
  '7a90c3feb1020c684066202bacc30669',
);

// This allows us to cancel a hits render if a new one is triggered before the last one finishes
let latestCallTimestamp = new Date().getTime();

// The algolia isFirstPage doesn't seem to ever change?
let isFirstPage = true;

const urlParams = new URLSearchParams(window.location.search);

async function generateProductsHtml(hits: any) {
  return await Promise.all(
    hits.map(async (hit) => {
      let classes: string = '';
      let images: any[] = [];

      let namedTags = hit.named_tags;

      images.push({
        // Default image
        url: hit.image
          ? getSizedShopifyImageUrl(hit.image, 768)
          : '/img/placeholder.png',
        srcSet: hit.image
          ? `${getSizedShopifyImageUrl(
              hit.image,
              768
            )} 767w, ${getSizedShopifyImageUrl(hit.image, 1200)} 1200w`
          : '/img/placeholder.png',
        class: 'primary',
      });

      // Make a call to get the images
      const productImages = await fetch(
        `/api/shop/product-images/${hit.id}`
      ).then((r) => r.json());

      if (productImages.length > 1) {
        images.push({
          url: productImages[1].thumbnail,
          srcSet: `${productImages[1].thumbnail} 767w, ${productImages[1].url} 1200w`,
          class: 'secondary',
        });

        classes += 'product-card--multi-image';

        if (namedTags.hover && namedTags.hover === 'lifestyle') {
          classes += ' product-card--lifestyle-image';
        }
      }

      let imageHTML = images
        .map((image) => {
          return `<img class="product-card__img product-card__img--${image.class} lazyload"
          data-src="${image.url}" data-srcset="${image.srcSet}" />`;
        })
        .join('');

      let badgeHTML = '';
      let buttonHTML = '';

      let tags = hit.tags;

      let available = namedTags.available
        ? hit.inventory_available
          ? true
          : false
        : false;

      if (
        hit.option_names.length > 1 ||
        hit.option_names.some((e) => e.includes('size')) ||
        tags?.includes('resizeable')
      ) {
        available = false;
      }

      if (namedTags.newArrival) {
        badgeHTML =
          '<span class="product-card__badge product-card__badge--new">New</span>';
      }

      if (hit.option_names.length > 1) {
        buttonHTML = '';
      } else if (available) {
        buttonHTML = `<button class="bag-btn product-card__add-to-cart js-add-to-cart" data-id="gid://shopify/ProductVariant/${hit.objectID}">
                <span class="sr-only">Add to Cart</span>
              </button>`;
      } else {
        buttonHTML = `<button class="bag-btn product-card__add-to-cart js-add-to-cart hidden" disabled data-id="gid://shopify/ProductVariant/${hit.objectID}">
                <span class="sr-only">Add to Cart</span>
              </button>`;
      }

      // variants should only show for colour options, not size etc.
      // We're showing 3, and if there are more than that the plus button shows
      let variantsHTML = '';

      // Check if there are colour or material variants for this item
      // If there is also a size available, don't show the colour picker
      if (
        hit.option_names.includes('color') ||
        hit.option_names.includes('colour') ||
        hit.option_names.includes('material')
      ) {
        // Make a call to get the variants
        const variantColours = await fetch(
          `/api/shop/variant-colours/${hit.id}`
        ).then((r) => r.json());

        if (variantColours.length > 1) {
          let html = variantColours
            .map((variant) => {
              let checked =
                Object.values(hit.options).indexOf(variant.value) === 0
                  ? 'checked'
                  : '';

              // we don't want to actually disable, but simulate instead
              let disabled = variant.inStock ? '' : 'disabled';
              // Don't set imageset attr if no variant image
              const imageSet = variant.image
                ? `${getSizedShopifyImageUrl(
                    variant.image,
                    768
                  )} 767w, ${getSizedShopifyImageUrl(
                    variant.image,
                    1200
                  )} 1200w`
                : '';
              // let disabled = '';
              return `<label class="variant-selectors__option variant-selectors__option--color variant-selectors__option--${
                variant.classes
              } js-mini-variant-selector">
              <input class="variant-selectors__input ${disabled}" type="radio" name="${
                hit.handle
              }-option-${variant.name}" value="${
                variant.value
              }" data-option="${variant.name}" data-product="${
                hit.handle
              }" data-imageset="${imageSet}" data-price="${formatAsMoney(
                variant.price
              )}" data-compare-at-price="${
                variant.compareAtPrice
                  ? formatAsMoney(variant.compareAtPrice)
                  : ''
              }" data-variant="${variant.id}" ${checked} />
              <span class="variant-selectors__option-btn" title="${
                variant.value
              }"></span>
              <span class="variant-selectors__option-strikethrough"></span>
              <span class="variant-selectors__option-underline"></span>
            </label>
            `;
            })
            .slice(0, 4)
            .join('');

          if (variantColours.length > 3) {
            html += '<span class="variant-selectors__additional">+</span>';
          }

          variantsHTML = `<div role="group" aria-labelledby="option-label_colour" class="variant-selectors variant-selectors--mini">
          <span class="sr-only" id="option-label__colour">Select a Colour</span>
          <div class="variant-selectors__buttons">
            ${html}
          </div>
        </div>`;
        }
      }

      // add to cart button needs to be conditional, should not show if its not
      // availble online, out of stock, or if there are more than just colour
      // selector options (ie if they need to choose a size or something, should not show)
      /****** If you make ANY EDITS to this you need to also update the ProductCard component file as well ******/

      let priceMarkup = '';
      // If price hidden
      if (hit.tags?.includes('hide-price')) {
        priceMarkup =
          '<p class="product-card__price product-card__price--hidden">Price upon request</p>';
      } else if (hit.compare_at_price && hit.price !== hit.compare_at_price) {
        // If has a different compare-at price, show it
        priceMarkup = `<p class="product-card__price js-product-price">
          <strike>$${hit.compare_at_price.toLocaleString(
            'en-US'
          )}</strike><span>$${hit.price.toLocaleString('en-US')}</span>
        </p>`;
      } else {
        // Default just show price
        priceMarkup = `<p class="product-card__price js-product-price">
          $${hit.price.toLocaleString('en-US')}
        </p>`;
      }

      return `
      <li class="product-list__grid-item">
        <div class="product-card product-card--sm ${classes} js-product" data-product="${hit.handle}">
            <a href="/products/${hit.handle}?v=${hit.objectID}" class="product-card__images" tabindex="-1"
              >${imageHTML}</a
            >

            ${badgeHTML}

            <div class="product-card__details">
              <h3 class="product-card__title"><a href="/products/${hit.handle}">${hit.title}</a></h3>
              ${priceMarkup}
            </div>

            <div class="product-card__btns">
              ${buttonHTML}
              ${variantsHTML}
            </div>

          </div>
        </li>
        `;
    })
  );
}

const genHeadingHtml = (heading: string) => {
  return `
  <div class="search-results__section-header">
    <div class="page-width mobile-padding">
      <h2 class="search-results__section-title">${heading}</h2>
    </div>
  </div>`;
};

const genViewAllBtnHtml = (type: 'exact' | 'similar') => {
  const notFoundPageQuery = document.querySelector<HTMLInputElement>('#notFoundQuery')?.value;

  // TODO: Disable or don't show link if no more items?
  const q = notFoundPageQuery || urlParams.get('q');
  const url = `/search/products/?q=${q}&matches=${type}`;
  console.debug({ url });
  return `
  <div class="search-results__load-more">
    <a href="${url}" class="search-results__load-more-btn btn btn--primary">View All</a>
  </div>`;
};

/**
 * Make async calls and render the hits once they've been loaded
 * @param hits The hits from the Algolia renderHits function
 * @param container The container to render the hits into
 * @param timestamp The timestamp for when this call was made. Used for skipping render if out of date.
 */
const loadHitsAsync = async (hits: any[], container: HTMLElement, timestamp: number) => {
  // console.debug(`🚀 ~ loadHitsAsync (@${timestamp}) start`);

  /** Matches URL param, to indicate exact vs similar (only on "view all" pages) */
  const matches = urlParams.get('matches');
  console.debug({ matches });

  const { exactResults, similarResults } = hits.reduce((accum, hit) => {
    // console.debug({
    //   title: hit.title,
    //   nbExactWords: hit._rankingInfo.nbExactWords,
    // });
    if (hit._rankingInfo.nbExactWords > 0) {
      accum.exactResults.push(hit);
    } else {
      accum.similarResults.push(hit);
    }
    return accum;
  }, { exactResults: [], similarResults: [] });

  let html = '';

  // Show exact results by default or if not specified 'similar'
  if (exactResults.length && matches !== 'similar') {
    const exactResultsHtml = await generateProductsHtml(exactResults);
    const exactResultsHeadingHtml = genHeadingHtml('Exact Product Results');
    // Don't show "view all" button if already on "view all" page
    const exactViewAllBtnHtml = matches ? '' : genViewAllBtnHtml('exact');

    html += `${isFirstPage ? exactResultsHeadingHtml : ''}
      <ol class="product-list__grid">${exactResultsHtml.join('')}</ol>
      ${isFirstPage ? exactViewAllBtnHtml : ''}`;
  }

  // Show similar results by default or if not specified 'exact'
  if (similarResults.length && matches !== 'exact') {
    const similarResultsHtml = await generateProductsHtml(similarResults);
    const similarResultsHeadingHtml = genHeadingHtml('Similar Product Results');
    // Don't show "view all" button if already on "view all" page
    const similarViewAllBtnHtml = matches ? '' : genViewAllBtnHtml('similar');

    html += `${isFirstPage ? similarResultsHeadingHtml : ''}
      <ol class="product-list__grid">${similarResultsHtml.join('')}</ol>
      ${isFirstPage ? similarViewAllBtnHtml : ''}`;
  }

  // If this call's timestamp is not out of date, update the UI
  if (latestCallTimestamp <= timestamp) {
    // console.debug(`🚀 ~ loadHitsAsync (@${timestamp}) updating`);

    // It's loading a new page so need to append the new results
    container.innerHTML += html;
  // } else {
  //   console.debug(`🚀 ~ loadHitsAsync (@${timestamp}) cancelled and skipping`);
  }

  const spinner = document.querySelector<HTMLElement>('.collection__grid--loading');
  if (spinner) {
    spinner.style.display = 'none';
  }

  isFirstPage = false;
};

let lastRenderArgs: any = null;
const renderHits = async (renderOptions, isFirstRender) => {
  // When renderHits is called, we update the latest timestamp
  latestCallTimestamp = new Date().getTime();

  const {
    hits,
    currentPageHits,
    widgetParams,
    // isFirstPage, // TODO: isFirstPage is always true?
    // isLastPage,
    showMore,
  } = renderOptions;

  lastRenderArgs = renderOptions;

  if (isFirstRender) {
    bindHitEventListeners();
    return;
  }

  if (!hits.length) {
    widgetParams.container.innerHTML = `
      <ol class="collection__grid collection__grid--empty">
        <li class="collection__grid-item collection__grid-item--empty">
          <p class="collection__empty-msg">No results found matching your selection.</p>
        </li>
      </ol>`;
    // If we hit here, we need to bump the timestamp to stop any in-progress `loadHits()` calls
    // from replacing the content
    // console.debug('🚀 ~ renderHits ~ cancelling)');
    latestCallTimestamp = new Date().getTime();
    return;
  } else if (document.getElementById('notFoundQuery')) {
    // If on 404 page and we do have results, unhide the search section
    document
      .querySelector('.search-results__section')
      ?.classList.remove('search-results__section--hidden');
  }

  // Show loading spinner
  const spinner = document.querySelector<HTMLElement>('.collection__grid--loading');
  if (spinner) {
    spinner.style.display = 'flex';
  }

  // NOTE: This is async but we just call it so it updates the UI when ready
  // console.debug(`🚀 ~ renderHits ~ calling loadHitsAsync (@${latestCallTimestamp})`);
  loadHitsAsync(currentPageHits, widgetParams.container, latestCallTimestamp);

  function bindHitEventListeners() {
    $('.search-results__product-grid').on('mouseover', '.js-product', (e) => {
      if (window.matchMedia('(pointer: fine)').matches) {
        const $card = e.currentTarget;
        $card.classList.add('hover');
      }
    });

    $('.search-results__product-grid').on('mouseout', '.js-product', (e) => {
      if (window.matchMedia('(pointer: fine)').matches) {
        const $card = e.currentTarget;
        $card.classList.remove('hover');
      }
    });

    $('.search-results__product-grid').on(
      'focusin',
      '.js-product input, .js-product a, .js-product button',
      (e) => {
        if (window.matchMedia('(pointer: fine)').matches) {
          const $card = e.currentTarget.closest('.js-product');

          if (!$card.classList.contains('hover')) {
            document
              .querySelector('.js-product.hover')
              ?.classList.remove('hover');
            $card.classList.add('hover');
          }
        }
      }
    );

    $('.search-results__product-grid').on(
      'blur',
      '.js-product input, .js-product a, .js-product button',
      (e) => {
        if (window.matchMedia('(pointer: fine)').matches) {
          const $card = e.currentTarget.closest('.js-product');
          const allInputs = $card.querySelectorAll('input,a,button');

          // if we're losing focus on the last element, aka tabbing into the next div
          if (e.currentTarget == allInputs[allInputs.length - 1]) {
            $card.classList.remove('hover');
          }

          // if we're losing focus on the first element and the new target
          // isn't in the same div, we're tabbing backwards
          if (!$card.contains(e.relatedTarget)) {
            $card.classList.remove('hover');
          }
        }
      }
    );

    $('.collection, .search-results__product-grid, .product-list').on(
      'click',
      '.js-mini-variant-selector',
      (e) => {
        let input = e.target;

        let id = input.dataset.variant;
        let imageSet = input.dataset.imageset;
        let price = input.dataset.price;
        let compareAtPrice = input.dataset.compareAtPrice;
        let handle = input.dataset.product;
        let available = input.classList.contains('disabled') ? false : true;

        let product = document.querySelector(
          `.js-product[data-product="${handle}"]`
        );

        if (product) {
          let addToCart = product.querySelector(
            '.js-add-to-cart'
          ) as HTMLInputElement | null;

          let links = product.querySelectorAll('a');

          links?.forEach((link) => {
            let url = new URL(link.href);
            let params = url.searchParams;

            params.set('v', extractID(id));

            url.search = params.toString();

            link.href = url.toString();
          });

          if (addToCart) {
            // update id on cart button
            addToCart.dataset.id = id;

            if (available) {
              addToCart.classList.remove('hidden');
              addToCart.disabled = false;
            } else {
              addToCart.classList.add('hidden');
              addToCart.disabled = true;
            }
          }

          if (imageSet) {
            // update image
            const imgEl = product.querySelector(
              '.product-card__img--primary'
            ) as HTMLImageElement;
            imgEl.srcset = imageSet;
            product.classList.add('disable-hover');
          }
          const priceEl = product.querySelector('.js-product-price');
          if (priceEl) {
            let priceMarkup = '';
            if (compareAtPrice && price !== compareAtPrice) {
              priceMarkup += `<strike>${compareAtPrice}</strike>`;
            }
            priceMarkup += `<span>${price}</span>`;
            priceEl.innerHTML = priceMarkup;
          }
        }
      }
    );

    // Add infinite loader intersection observer
    const pageEnd = document.querySelector('#page-end');
    if (!pageEnd) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !lastRenderArgs.isLastPage) {
          showMore();
        }
      });
    });
    observer.observe(pageEnd);
  }
};

const customInfiniteHits = connectInfiniteHits(renderHits);

function init(searchClient: SearchClient): void {
  const search = instantsearch({
    indexName: DEFAULT_INDEX,
    searchClient,
    routing: {
      router: history(),
      stateMapping: {
        // @ts-ignore Algolia typing error
        stateToRoute(uiState) {
          const indexUiState = uiState[DEFAULT_INDEX];
          // console.debug({ indexUiState });
          // This is the format used below in routeToState
          return {
            q: indexUiState.query,
            page: indexUiState.page,
            matches: urlParams.get('matches'),
          };
        },
        // @ts-ignore Algolia typing error
        routeToState(routeState) {
          return {
            [DEFAULT_INDEX]: {
              // Load query from query input (views/search/components/product-results.edge) or query string
              query: (document.getElementById('notFoundQuery') as HTMLInputElement | null)?.value || routeState.q,
            },
          };
        },
      },
    },
  });

  document.addEventListener('DOMContentLoaded', () => {
    const virtualSearchBox = connectSearchBox(() => null);

    const searchWidgets: any[] = [
      configure({
        filters: '',
        hitsPerPage: 12,
        getRankingInfo: true,
      }),
      virtualSearchBox({}),
      customInfiniteHits({
        // @ts-ignore (apparently the types haven't been added/updated for custom connectors yet?)
        container: document.querySelector('#product-hits'),
      }),
    ];

    // Add search results count widget if present
    if (document.querySelector('#search-results-count')) {
      searchWidgets.push(
        stats({
          container: '#search-results-count',
          templates: {
            text(data, { html }) {
              let count = data.nbHits;
              let countText = '';

              document
                .querySelectorAll('.search-results__grid')
                .forEach((section: HTMLElement) => {
                  if (section.dataset['total']) {
                    count += parseInt(section.dataset['total']);
                  }
                });

              if (count > 1) {
                countText += `${count} Results Found`;
              } else if (count === 1) {
                countText += '1 Result Found';
              } else {
                countText += 'No Results Found';
              }

              // NOTE: IF YOU EVER CHANGE THIS, MAKE SURE TO ALSO UPDATE view-all.edge
              return html`<h1 class="search-results__hero-eyebrow">
                  Search Results
                </h1>
                <p class="search-results__hero-title">
                  ${countText} for "${urlParams.get('q')}"
                </p>`;
            },
          },
        })
      );
    }

    search.addWidgets(searchWidgets);
    search.start();
  });
}

init(searchClient);
